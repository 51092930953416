



































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import vertragsdaten from '@/components/form/vertragsdaten.vue';
// import FormSwitch from '@/components/form/form-switch.vue';
import IVertragsfaten from '@/model/IVertragsfaten';
import maschinenartValues from '@/config/maschinenart-values';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import { unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  onCurrencyFocus } from '@/helper/functions';
import { MACHINE_KRAFTWERKE } from '@/constant/constants';

const erstrisikosummenItem = {
  name: '',
  valueERS: '',
  valueGeanderteERS: 0,
  valueBeitragssatz: '',
  valueStatic: false,
};

const additionalParam = {
  name: 'test',
  value: 0,
};

const vertragsbezogeneZuschlageItem = {
  Regressverzicht: '',
  MitversicherungInnereUnruhen: '',
  calculat: false,
  additionalParams: [
    {
      ...additionalParam,
    },
  ],
};
const positionsdatenItem = {
  isSelected: false,
  Maschinenart: {
    value: '',
    label: '',
  },
  MKZ: '',
  GB: '',
  SbPercent: null,
  SbMin: '',
  SbMax: '',
  Beschreibung: '',
  Bewertungsjahr: new Date().getFullYear(),
  Versicherunswert: 0,
  FestwertGleitend: 'Gleitend',
  Pramiensatz: '',
  VSU: '',
  Faktor: '',
  Deckungsform: '',
  SB: 0,
  Neuheitsrabatt: '',
  Spindelklausel: false,
  MitversicherungFeuerInMASS: '',
  MitversicherungUberschwemmungInMASS: '',
  MitversicherungErdbebenInMASS: '',
  stillstandsrabatt: '',
  SchadenDurchVuV: '',
  VuVInldDruckrohrleitungen: '',
  EinsatzUnterTage: '',
  VermietungUndVerpachtung: '',
  GAPDeckung: false,
  UnterschlagungFOTGerate: false,
  VandalismusMTV: false,
  BeitragVar1: 0,
  BeitragVar2: 0,
  BeitragVar3: 0,
};
const geltungsbereicheItem = {
  isSelected: false,
  num: 0,
  strasse: '',
  plz: '',
  ort: '',
  land: '',
  zuers: 0,
  naturgefahren1: '',
  naturgefahren2: '',
  naturgefahren3: '',
  geltungsbereich: '',
  individualAgreements: '',
};
const pramienSelbstbehaltsvariantenItem = {
  isSelected: false,
  SB: '',
  SbPercent: null,
  SbMin: '',
  SbMax: '',
  SBRabattfaktor: '',
  TarifpramiensatzAktuellNetto: '',
  Tarifpramiensatz: '',
  TarifpramieNetto: '',
};
interface PriceItem {
  machineCodeNumber: string;
  premiumFactor: string;
  sumFactor: string;
  codeSFPF: string;
  codeABMGDiebstahl: string;
  assessmentYear: string;
  evaluationPrice: string;
  codeVSu: string;
  codeSachAus: string;
  factorPremiumRate: string;
  codeDeductibleVar1: string;
  discount1: string;
  discount2: string;
  codeSBBUVar1: string;
}
const vertragsbezogeneZuschlageType = 2;
const positionsdatenType = 3;
const pramienSelbstbehaltsvariantenType = 4;
const erstrisikosummenType = 5;
const geltungsbereicheType = 6;
export default Vue.extend({
  name: 'machines-power-plants-calc',
  mixins: [dataChangeMixin],
  components: {
    vertragsdaten,
    vSelect,
    // FormSwitch,
  },
  data() {
    return {
      name: 'machines-power-plants-calc',
      key: 'MACHINES_POWER_PLANTS_INSURANCE',
      backUrl: 'elektronik-insurance',
      vertragsdatenOpen: true,
      sbOpen: true,
      rabatteOpen: false,
      beitragOpen: false,
      maschinenartValues,
      paramsVertragsdaten: {
        sumInsured: 0,
        sum371: 0,
        paymentMethod: '',
        versicherungssteuer: 0,
        netization: '',
        factorLevel: '',
      },
      vertragsbezogeneZuschlageItems: [
        {
          Regressverzicht: '',
          MitversicherungInnereUnruhen: '',
          calculat: false,
          additionalParams: [],
        },
      ],
      positionsdatenItems: [
        {
          ...positionsdatenItem,
        },
      ],
      pramienSelbstbehaltsvariantenItems: [
        {
          name: 'SB-Variante 1',
          ...pramienSelbstbehaltsvariantenItem,
        },
        {
          name: 'SB-Variante 2',
          ...pramienSelbstbehaltsvariantenItem,
        },
        {
          name: 'SB-Variante 3',
          ...pramienSelbstbehaltsvariantenItem,
        },
      ],
      geltungsbereicheItems: [
        {
          ...geltungsbereicheItem,
        },
      ],
      pramienSelbstbehaltsvariantenItem,
      erstrisikosummenItems: [
        {
          name: 'Aufräumungs-, Bergungs, Dekontaminations- und Ensorgungskosten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Bewegungs- und Schutzkosten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Luftfrachtkosten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Erd-, Pflaster-, Maurer- und Stemmarbeiten',
          valueERS: 25000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Kosten für Daten',
          valueERS: 10000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
        {
          name: 'Kosten für Schäden an Gebäuden',
          valueERS: 10000,
          valueGeanderteERS: 0,
          valueBeitragssatz: '',
          valueStatic: true,
        },
      ],
      ByVertragsbezogeneZuschlageType: vertragsbezogeneZuschlageType,
      ByPositionsdatenType: positionsdatenType,
      BypramienSelbstbehaltsvariantenType: pramienSelbstbehaltsvariantenType,
      ByerstrisikosummenType: erstrisikosummenType,
      BygeltungsbereicheType: geltungsbereicheType,
      notizen: '',
      calculation: null,
      noPrice: false,
      price: 0,
      pdfStyles: false,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'processSavingData', 'productId', 'id'],
  watch: {
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.firstOpenTab) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.anschriftNeubau);
      }
    },
  },
  methods: {
    onCurrencyFocus,
    goToBackUrl() {
      this.onSubmit();
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Kraftwerke-Tarif-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1650,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    changeMaschinenart(index: number) {
      this.positionsdatenItems[index].MKZ = this.getMkzNumberBychangeMaschinenart(this.positionsdatenItems[index].Maschinenart.label);
    },
    changeMaschinenartCode(index: number) {
      this.positionsdatenItems[index].Maschinenart = this.getMachineTypeByCode(this.positionsdatenItems[index].MKZ);
    },
    getMachineTypeByCode(code: string): any {
      return this.maschinenartValues.find((itm) => itm.value.endsWith(`-${code}`));
    },
    getMkzNumberBychangeMaschinenart(machineType: string): string {
      const foundItem = this.maschinenartValues.find((itm) => itm.label === machineType);

      if (foundItem) {
        const codeData = foundItem.value.split('-');

        return codeData[1] || '';
      }

      return '';
    },
    fillVersicherungsortItemsFromGruppendaten(basicData: any) {
      this.geltungsbereicheItems[0].strasse = basicData.Strabe;
      this.geltungsbereicheItems[0].plz = basicData.PLZ;
      this.geltungsbereicheItems[0].ort = basicData.Ort;
      this.geltungsbereicheItems[0].land = basicData.Land;
      this.geltungsbereicheItems[0].zuers = basicData.Zurs;
      this.geltungsbereicheItems[0].naturgefahren1 = basicData.Naturgefahrenklasse1;
      this.geltungsbereicheItems[0].naturgefahren2 = basicData.Naturgefahrenklasse2;
      this.geltungsbereicheItems[0].naturgefahren3 = basicData.Naturgefahrenklasse3;
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummenItems.splice(key, 1);
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.ByPositionsdatenType:
          unselectFirstElement(this.positionsdatenItems);

          items = this.positionsdatenItems.filter((item) => !item.isSelected);
          this.positionsdatenItems = items;
          break;
        case this.BygeltungsbereicheType:
          unselectFirstElement(this.geltungsbereicheItems);

          items = this.geltungsbereicheItems.filter((item) => !item.isSelected);
          this.geltungsbereicheItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    addAdditionalParam(type: number) {
      const item = additionalParam;
      switch (type) {
        case this.ByVertragsbezogeneZuschlageType:
          this.vertragsbezogeneZuschlageItems.forEach((element: any, index) => {
            // item.value = index;
            element.additionalParams.push({
              ...item,
            });
          });
          break;
        default:
          console.log('Wrong type');
      }
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.ByVertragsbezogeneZuschlageType:
          duplicateDataItemsWithoutSelect(vertragsbezogeneZuschlageItem, this.vertragsbezogeneZuschlageItems);
          break;
        case this.ByPositionsdatenType:
          duplicateDataItems(positionsdatenItem, this.positionsdatenItems);
          break;
        case this.ByerstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenItem, this.erstrisikosummenItems);
          break;
        case this.BygeltungsbereicheType:
          duplicateDataItems(geltungsbereicheItem, this.geltungsbereicheItems, fillData);
          break;
        default:
          console.log('Wrong type');
      }
    },
    paramsVertragsdatenChange(params: IVertragsfaten) {
      this.paramsVertragsdaten.sumInsured = params.sumInsured;
      this.paramsVertragsdaten.sum371 = params.sum371;
      this.paramsVertragsdaten.paymentMethod = params.paymentMethod;
      this.paramsVertragsdaten.versicherungssteuer = params.versicherungssteuer;
      this.paramsVertragsdaten.netization = params.netization;
      this.paramsVertragsdaten.factorLevel = params.factorLevel;
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData.spreadsheetData;
      this.paramsVertragsdaten = calculationData.paramsVertragsdaten;
      this.vertragsbezogeneZuschlageItems = calculationData.vertragsbezogeneZuschlageItems;
      this.positionsdatenItems = calculationData.positionsdatenItems;
      this.erstrisikosummenItems = calculationData.erstrisikosummenItems;
      this.notizen = calculationData.notizen;
      this.geltungsbereicheItems = calculationData.geltungsbereicheItems;

      if (calculationData.pramienSelbstbehaltsvariantenItems[0]) {
        this.pramienSelbstbehaltsvariantenItem = calculationData.pramienSelbstbehaltsvariantenItem;
        this.pramienSelbstbehaltsvariantenItems = calculationData.pramienSelbstbehaltsvariantenItems;
      } else {
        this.pramienSelbstbehaltsvariantenItem = this.setPramienSelbstbehaltsvariantenItem(calculationData.pramienSelbstbehaltsvariantenItems.tarif);
        this.pramienSelbstbehaltsvariantenItems = [
          {
            name: 'SB-Variante 1',
            ...calculationData.pramienSelbstbehaltsvariantenItems.variant1,
          },
          {
            name: 'SB-Variante 2',
            ...calculationData.pramienSelbstbehaltsvariantenItems.variant2,
          },
          {
            name: 'SB-Variante 3',
            ...calculationData.pramienSelbstbehaltsvariantenItems.variant3,
          },
        ].map((itm) => this.setPramienSelbstbehaltsvariantenItem(itm));
      }
    },
    setPramienSelbstbehaltsvariantenItem(data: any): any {
      return {
        ...data,
        SbPercent: data.SBpercent,
        SbMin: data.SBmin,
        SbMax: data.SBmax,
      };
    },
    setPrice() {
      const machinery: PriceItem[] = [];
      // const priceApiData = [];
      this.positionsdatenItems.forEach((element: any, index) => {
        const machineryItem = {
          machineCodeNumber: element.MKZ,
          premiumFactor: '',
          sumFactor: '',
          codeSFPF: 'L001C0001',
          codeABMGDiebstahl: 'L002C0001',
          assessmentYear: element.Bewertungsjahr,
          evaluationPrice: element.Versicherunswert,
          codeVSu: 'L101C0006',
          codeSachAus: 'L102C0001',
          factorPremiumRate: element.Pramiensatz,
          codeDeductibleVar1: element.SB,
          discount1: '',
          discount2: '',
          codeSBBUVar1: '',
        };
        machinery.push(machineryItem);
      });
      const priceData = {
        insuranceKey: this.key,
        // productId: '51c9aa29-3ff3-4e2b-8f73-95bfe638adec',
        codeProductId: 'L000C0001',
        codeSubProductId: 'L100C0001',
        machinery,
        awardOverview: {
          codePaymentMethod: this.paramsVertragsdaten.paymentMethod,
          bonusYear: '2022',
          commission: '',
          insuranceTax: '0',
        },
      };
      this.$emit('price-card-data', priceData);
    },
    onSubmit() {
      const data = {
        paramsVertragsdaten: this.paramsVertragsdaten,
        vertragsbezogeneZuschlageItems: this.vertragsbezogeneZuschlageItems,
        positionsdatenItems: this.positionsdatenItems,
        pramienSelbstbehaltsvariantenItem: this.pramienSelbstbehaltsvariantenItem,
        pramienSelbstbehaltsvariantenItems: this.pramienSelbstbehaltsvariantenItems,
        erstrisikosummenItems: this.erstrisikosummenItems,
        geltungsbereicheItems: this.geltungsbereicheItems,
        notizen: this.notizen,
      };

      this.$emit('spreadsheet-data-change', data, this.key);

      this.setPrice();
    },
  },
  mounted() {
    if (!this.firstOpenTab) {
      this.setValues(this.insuranceData);
    } else {
      this.$emit('on-key-change', this.key);
    }
  },
  computed: {
    isKraftwerke() {
      if (this.insuranceData && this.insuranceData.basicData.vertragsart === MACHINE_KRAFTWERKE) {
        return true;
      }
      return false;
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummenItems.length < 12);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummenItems.length > 8);
    },
    firstOpenTab(): boolean {
      return (this.insuranceData === null || this.insuranceData.spreadsheetData === null);
    },
  },
});
